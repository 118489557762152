
import { defineComponent, reactive, onMounted, toRefs, ref } from "vue";
import { Api } from "@/assets/Api";
import store from "@/store";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import type { FormInstance } from "element-plus";

export default defineComponent({
  name: "Main",
  components: {},
  setup() {
    let data = reactive<any>({
      menu: {},
      nickname: "",
      dialogLoginTableVisible: false,
      passwordForm: {
        oldPass: "",
        pass: "",
        checkPass: "",
      },
    });
    const ruleFormRef = ref<FormInstance>();
    const router = useRouter();

    const logout = () => {
      window.sessionStorage.clear();
      router.push({
        path: "/",
      });
    };

    const getConfig = () => {
      const menu: string = store.getters.menu;
      console.log("menu----------" + menu);
      if (menu) {
        data.menu = JSON.parse(menu);
      } else {
        Api.User.Config().then((res: any) => {
          console.log(res);
          if (res.Code == 0) {
            data.menu = res.Data.Menu;
            store.dispatch("SetMenu", JSON.stringify(res.Data.Menu));
            store.dispatch("SetRegInfo", JSON.stringify(res.Data.RegInfo));
            console.log(data.menu);
            router.push({
              path: "/work",
            });
          } else {
            ElMessage.warning({
              showClose: true,
              duration: 10000,
              message: res.ResultMsg,
            });
          }
        });
      }
    };

    const validatePass = (rule: any, value: any, callback: any) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        if (data.passwordForm.checkPass !== "") {
          if (!ruleFormRef.value) return;
          ruleFormRef.value.validateField("checkPass", () => null);
        }
        callback();
      }
    };

    const validatePass2 = (rule: any, value: any, callback: any) => {
      if (value === "") {
        callback(new Error("请再次输入新密码"));
      } else if (value !== data.passwordForm.pass) {
        callback(new Error("两次新密码不一致"));
      } else {
        callback();
      }
    };

    const rules = reactive({
      pass: [{ validator: validatePass, trigger: "blur" }],
      checkPass: [{ validator: validatePass2, trigger: "blur" }],
      oldPass: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
    });

    const submitForm = (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      formEl.validate((valid) => {
        if (valid) {
          const dot = {
            OldPassword: data.passwordForm.oldPass,
            NewPassword: data.passwordForm.pass,
          };
          Api.User.ChangePassword(dot).then((res: any) => {
            console.log(res);
            if (res.Code == 0) {
              ElMessage.success(res.ResultMsg);
              formEl.resetFields();
              data.dialogLoginTableVisible = false;
            } else {
              ElMessage.warning({
                showClose: true,
                duration: 10000,
                message: res.ResultMsg,
              });
            }
          });

          console.log("submit!");
        } else {
          console.log("error submit!");
          return false;
        }
      });
    };

    const resetForm = (formEl: FormInstance | undefined) => {
      if (!formEl) return;
      formEl.resetFields();
    };

    onMounted(() => {
      data.nickname = store.getters.nickname;
      console.log("configxxxx" + store.getters.menu);

      getConfig();
    });

    return {
      ...toRefs(data),
      ruleFormRef,
      validatePass,
      validatePass2,
      submitForm,
      resetForm,
      rules,
      logout,
    };
  },
});
